import React, { useContext, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { register, handleForm, data, getCookie, client, socialLogin, url, fbLogin } from '../data/functions';
import { AppContext } from '../reducers/AppContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';


const Formconnect = (props) => {

    const navigate = useNavigate();
    const { dispatch, randomutility } = useContext(AppContext);
    const [modalFormRegister, SetModalFormRegister] = useState(false);
    const [username, SetUsername] = useState();
    const [email, SetEmail] = useState();
    const [socialusername, SetSocialusername] = useState();
    const [socialemail, SetSocialemail] = useState();
    const [modalTerms, SetmodalTerms] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required(),
        email: yup.string().required().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Enter a valid email format'),
        password: yup.string().required(),
        confirm_password: yup.string().oneOf([yup.ref('password')], 'Your passwords do not match.').required(),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });

    const onSubmit = async (values, actions) => {
        await new Promise((resolve) => setTimeout(() => {
            data['username'] = values.firstName.toLowerCase();
            data['email'] = values.email;
            data['password'] = values.password;

            register(data)
            // console.log(JSON.stringify(values, null, 2));
            console.log(data);

            document.getElementById('loading_2').innerHTML = '<div class="alert alert-success" role="alert">Done!</div>';
            document.getElementById('loading_2').style.display = 'block';
            var nv = document.getElementById("nav-business-created");
            // var next = nv.querySelectorAll(".next");
            // next[0].classList.remove('disabled')
        }, 1000))
        // actions.resetForm();
    }

    const { handleSubmit, handleChange, isSubmitting, values, touched, errors } = useFormik({
        validationSchema: schema,
        initialValues: {
            firstName: '',
            email: '',
            password: '',
            confirm_password: '',
            terms: false,
        },
        onSubmit,
    });

    const submitLogin = (data) => {
        document.getElementById('loading').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        document.getElementById('loading').style.display = 'block';
        client.post('api/login/', data,
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            // console.log(res.data);
            dispatch({
                type: 'USER-CONNECT'
            });
            sessionStorage.setItem("id", res.data[2]);
            sessionStorage.setItem("user", res.data[1]);
            sessionStorage.setItem('super', res.data[3]);
            sessionStorage.setItem('email', res.data[0])
            SetUsername(res.data[1]);
            document.getElementById('loading').innerHTML = '<div class="alert alert-success capitalize-hp" role="alert">Welcome ' + res.data[1] + '</div>';
            client.get('api/user/' + sessionStorage.getItem('id') + '/',
                { withCredentials: true },
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                // SetIsbarber(res.data[0]['is_barber'])
                if (res.data[0]['is_barber']) {
                    client.get('api/store/appointment/user/' + sessionStorage.getItem('id') + '/' + sessionStorage.getItem('st') + '/',
                        { withCredentials: true },
                        {
                            headers: { "X-CSRFToken": getCookie('csrftoken') },
                        }
                    ).then((res_2) => {
                        // console.log(res.data.length)
                        dispatch({
                            type: 'ISBARBER',
                            event: { 'barber': res.data[0]['is_barber'], 'countAppointment': res_2.data.length }
                        });
                    }).catch((err) => {
                        console.log(err);
                        return false
                    })
                }
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            dispatch({
                type: 'USER-CONNECT-NOT'
            });
            document.getElementById('loading').innerHTML = '<div class="alert alert-danger" role="alert">Oups error try again</div>';
        })
    }

    const responseFacebook = async (response) => {
        // console.log(response.data.accessToken);
        let fbResponse = await fbLogin(response.data.accessToken);
        dispatch({
            type: 'USER-CONNECT'
        });
        // console.log(fbResponse);
        if (fbResponse == '200') {
            document.getElementById('loading').innerHTML = '<div class="text-center"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
            document.getElementById('loading').style.display = 'block';
            client.post('api/socialaccount-verif/', { 'uid': response.data.userID },
                { withCredentials: true },
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                if (res.data.content == 0) {
                    document.getElementById('socialForm').style.display = 'block';
                    sessionStorage.setItem("key", res.data.key);
                    sessionStorage.setItem("id", res.data.id);
                } else {
                    sessionStorage.setItem("id", res.data.id);
                    sessionStorage.setItem("key", res.data.key);

                    client.get('api/socialuser/' + res.data.key + '/',
                        { withCredentials: true },
                        {
                            headers: { "X-CSRFToken": getCookie('csrftoken') },
                        }
                    ).then((res) => {
                        if (res.status == 200) {
                            console.log(res)
                            sessionStorage.setItem('user', res.data[0].username)
                            SetUsername(res.data[0].username);
                            sessionStorage.setItem('email', res.data[0].email)
                            dispatch({
                                type: 'USER-CONNECT'
                            });
                            document.getElementById('loading').innerHTML = '<div class="alert alert-success capitalize-hp" role="alert">Welcome ' + res.data[0].username + '</div>';
                        }
                        return true
                    }).catch((err) => {
                        console.log(err);
                        return false
                    })
                    return res.status;
                }
                console.log(res.data);
            }).catch((err) => {
                document.getElementById('loading').innerHTML = '<div class="alert alert-danger" role="alert">Oups error try again</div>';
                setTimeout(() => {
                    document.getElementById('loading').style.display = 'none';
                }, 3000);
                console.log(err);
                return false
            })
        } else {
            document.getElementById('socialForm').style.display = 'none';
        }
    };

    const submitInfo = (data) => {
        data['id'] = sessionStorage.getItem('key')
        client.post('api/user/change/', data,
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            if (res.status == 201) {
                sessionStorage.setItem('user', res.data.username)
                SetUsername(res.data.username);
                sessionStorage.setItem('email', res.data.email)
                dispatch({
                    type: 'USER-CONNECT'
                });
                document.getElementById('loading').style.display = 'block';
            }
            return true
        }).catch((err) => {
            // console.log(err);
            document.getElementById('loading').innerHTML = '<div class="alert alert-danger" role="alert"> Username or Email already exist</div>';
            setTimeout(() => {
                document.getElementById('loading').style.display = 'none';
            }, 3000);
            return false
        })
    }
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='content-social'>
                        <div className='content-social-login'>
                            <div className='mb-2'>Connect with :</div>
                            <LoginSocialFacebook
                                isOnlyGetToken
                                appId='801531125191335'
                                // onLoginStart={onLoginStart}
                                className='mb-2'
                                onResolve={(data) => {
                                    responseFacebook(data)
                                    console.log(data)
                                }}
                                onReject={(err) => {
                                    console.log(err)
                                }}
                            >
                                <FacebookLoginButton
                                    text='Facebook'
                                    iconColor='#0c3762' />
                            </LoginSocialFacebook>
                        </div>
                        <Form id='socialForm'>
                            <div style={{
                                textAlign: 'center',
                                margin: '0 60px 0',
                                fontSize: 'clamp(0.8rem, 0.9vw, 2rem)'
                            }}>
                                As a final step, please complete the following form:
                            </div>
                            <Form.Group className="mb-3" controlId="formSocialUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="username" placeholder="Enter your username" onChange={(e) => handleForm(e)} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formSocialEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter your email" onChange={(e) => handleForm(e)} required />
                            </Form.Group>
                            {/* <div id="loading" style={{ display: 'none', textAlign: 'center' }}>
                                {
                                    (randomutility) ? <Alert variant='success' style={{ textAlign: 'center' }}>Welcome {username}</Alert> : <Spinner animation="border" />
                                }
                            </div> */}
                            <div className='content-social-login-button'>
                                <Button style={{ marginBottom: 15 }} onClick={() => submitInfo(data)}>Envoyer</Button>
                            </div>
                        </Form>
                        <div className='content-social-login-divider'>
                            <span className='or mb-2'>OR</span>
                            <hr />
                        </div>
                    </div>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="username" placeholder="Enter your name" onChange={(e) => handleForm(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Enter your password</Form.Label>
                            <Form.Control type="password" name="password" placeholder="Your password" onChange={(e) => handleForm(e)} />
                        </Form.Group>
                        <div id="loading" style={{ display: 'none', textAlign: 'center' }}>
                            {
                                (randomutility) ? <Alert variant='success' style={{ textAlign: 'center' }}>Welcome {username}</Alert> : <Spinner animation="border" />
                            }
                        </div>
                    </Form>
                    <p>
                        Don't have an account?&nbsp;
                        <i><a href="#" style={{ color: '#00bfff' }}
                            onClick={() => SetModalFormRegister(true)}
                        >Register</a></i>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant="danger">Close</Button>
                    <Button className='btn-hairpro' onClick={() => submitLogin(data)}>Sign in</Button>
                </Modal.Footer>
            </Modal >
            <Modal
                show={modalFormRegister}
                onHide={() => SetModalFormRegister(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Sign Up
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label for='firstName'>Name :</Form.Label>
                            <Form.Control
                                type='text'
                                id='firstName'
                                placeholder='first name'
                                value={values.firstName}
                                onChange={handleChange}
                                isValid={touched.firstName && !errors.firstName}
                                isInvalid={!!errors.firstName}
                            />
                            <div class="invalid-feedback">
                                Valid name is required.
                            </div>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label for='email'>Email:</Form.Label>
                            <Form.Control
                                type='email'
                                id='email'
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                                placeholder='you@example.com'
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <div class="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label for='password'>Password :</Form.Label>
                            <Form.Control
                                type='password'
                                id='password'
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                                placeholder='create a password'
                            />
                            <div class="invalid-feedback">
                                Please enter valid password.
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
                            <Form.Label for='password'>Confirm password :</Form.Label>
                            <Form.Control
                                type='password'
                                id='confirm_password'
                                name='confirm_password'
                                value={values.confirm_password}
                                onChange={handleChange}
                                isValid={touched.confirm_password && !errors.confirm_password}
                                isInvalid={!!errors.confirm_password}
                                placeholder='confirm password'
                            />
                            <Form.Control.Feedback>Password match.</Form.Control.Feedback>
                            <div class="invalid-feedback">
                                Password are not match.
                            </div>
                        </Form.Group>

                        <span>Please read <a href="#" style={{ textDecoration: 'none', fontStyle: 'italic', color: '#00bfff' }} onClick={() => SetmodalTerms(true)}> Terms and conditions</a></span>
                        <Form.Group className="mb-3">
                            <Form.Check
                                required
                                name="terms"
                                label="Agree to terms and conditions"
                                onChange={handleChange}
                                isInvalid={!!errors.terms}
                                feedback={errors.terms}
                                feedbackType="invalid"
                                id="terms"
                            />
                        </Form.Group>

                        <div id="loading_2" style={{ display: 'none', textAlign: 'center' }}>
                            <Spinner animation="border" />
                        </div>
                        <Button type="submit" className='my-3'>Send&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                            <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                        </svg></Button>
                    </Form>
                    <p>
                        Already register? Go back to <i>
                            <a href="#" style={{ color: '#00bfff' }}
                                onClick={() => SetModalFormRegister(false)}
                            >Sign in</a></i>
                    </p>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalTerms}
                onHide={() => SetmodalTerms(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                scrollable="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Terms & Conditions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-terms'>
                    <b>1. Introduction</b>
                    <p>Bienvenue sur notre plateforme. En utilisant notre application, vous acceptez de respecter les termes et conditions décrits ci-dessous. Cette plateforme permet aux utilisateurs de proposer leurs services en ligne et de permettre à leurs clients de prendre des rendez-vous pour bénéficier de ces services.</p>

                    <b>2. Responsabilité des Prestataires de Services</b>
                    <p>Les utilisateurs de notre plateforme qui proposent des services sont entièrement responsables de la légalité de leurs activités professionnelles. Cela inclut, sans s'y limiter, l'obtention de toutes les licences, certifications ou autorisations nécessaires pour exercer leur métier conformément aux lois en vigueur dans leur juridiction.</p>

                    <b>3. Non-Responsabilité en Cas de Non-Conformité</b>
                    <p>La société <b>CanWeb Innov Inc.</b> décline toute responsabilité en cas de poursuites judiciaires, d'amendes ou de pénalités résultant de l'exercice illégal d'une profession par un utilisateur de la plateforme. Il est de la responsabilité exclusive de chaque prestataire de service de s'assurer qu'il est en conformité avec les lois locales, étatiques et nationales applicables à son domaine d'activité.</p>

                    <b>4. Vérification des Licences et Certifications</b>
                    <p>La plateforme ne vérifie pas de manière proactive les licences ou certifications des prestataires de services. En conséquence, les clients sont invités à effectuer leurs propres vérifications concernant les qualifications du prestataire avant de prendre un rendez-vous.</p>

                    <b>5. Indemnisation</b>
                    <p>En utilisant cette plateforme, vous acceptez de dégager <b>CanWeb Innov Inc.</b> de toute responsabilité et de l'indemniser contre toute réclamation, action en justice, ou perte résultant de votre non-conformité aux obligations légales de votre profession.</p>

                    <b>6. Modifications des Termes et Conditions</b>
                    <p><b>CanWeb Innov Inc.</b> se réserve le droit de modifier ces termes et conditions à tout moment. Les utilisateurs seront informés de toute modification importante via l'application ou par e-mail.</p>

                    <b>7. Responsabilité des Prestataires</b>
                    <p>En vous inscrivant en tant que prestataire de services sur <b>CanWeb Innov Inc.</b>, vous reconnaissez et acceptez que vous êtes seul responsable de l'obtention et du maintien de toutes les licences et autorisations nécessaires pour exercer votre métier. Vous vous engagez également à indemniser et dégager de toute responsabilité <b>CanWeb Innov Inc.</b>, ses dirigeants, employés et partenaires, en cas de réclamations, poursuites judiciaires, ou sanctions légales liées à votre activité professionnelle.</p>

                    <b>8. Litiges et Poursuites Judiciaires</b>
                    <p>Dans le cas où un client ou une tierce partie engagerait des poursuites judiciaires contre vous en raison de votre activité exercée via notre plateforme, <b>CanWeb Innov Inc.</b> ne sera en aucun cas tenu responsable. Vous acceptez d'assumer l'entière responsabilité de toute réclamation, et vous comprenez que l'utilisation de notre plateforme ne vous protège pas contre les obligations légales liées à votre métier.</p>
                </Modal.Body>
            </Modal>
        </>

    );
};

export default Formconnect;