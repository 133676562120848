import axios from 'axios';

// get cookies id for the navigator
export const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// export const client_url = "http://localhost:3000"
export const client_url = "https://flexiserve-9c9faacee014.herokuapp.com"

export const stripe_pk = "pk_live_51PIjB0IhkOM7ZfAhWSvigX6U4tkwWq0amnYqC4jnyvboXQw6dZhzq0Yl2tsDck0oRwwbcot1ChchoHuFEBhCCD9D00OFPmkVjr"

export const url = "https://flexiserve.onrender.com"
// export const url = "http://localhost:8000"

axios.defaults.withCredentials = true;

export const client = axios.create({
    baseURL: url
})

export let store_data = {
    name: "",
    email: "",
    tel: "",
    store_apt: "",
    store_address: "",
    store_country: "",
    store_city: "",
    store_state: "",
    store_cp: "",
    store_id: "",
    abonnement: "",
    price: "",
    id_payment: "",
    certify_status: "",
    id_user: "",
    storeCategory: ""
}

export let data = {
    username: "",
    lastname: "",
    email: "",
    password: "",
    pseudo: "",
    tel: "",
    image: "",
    isbarber: false,
    superuser: false,
    id: sessionStorage.getItem('st'),
}

var urlcourante = document.location.href;
var tabUrlCourant = urlcourante.split('/')
var getID = tabUrlCourant.slice(-1)[0]
export let getCateg = 'AD'

if (sessionStorage.getItem('ct')) {
    getID = sessionStorage.getItem('st')
}

const request = new XMLHttpRequest();
request.open("GET", url + "/api/store/" + getID + "/contact/", false); // `false` makes the request synchronous
request.send(null);

if (request.status === 200) {
    var res = JSON.parse(request.responseText)
    switch (res[0].category) {
        case 'Consultant':
            getCateg = 'AD'
            break;
        case 'Other':
            getCateg = 'AD'
            break;
        case 'Beauty':
            getCateg = 'ES'
            break;
        case 'Foods':
            getCateg = 'FD'
            break;
        case 'Cleaning':
            getCateg = 'CL'
            break;
        default:
            break;
    }
    sessionStorage.setItem('ct', res[0].category);
    sessionStorage.setItem('st_name', res[0].name);

    data['id'] = res[0].id

}

// Liste des images for header
export const backgroundImage = {
    ////// Image baniere ////////
    img_1: require('../img/IMG_' + getCateg + '_01.webp'),
    img_2: require('../img/IMG_' + getCateg + '_02.webp'),
    img_3: require('../img/IMG_' + getCateg + '_03.webp'),

    ////// Image body ////////
    img_4: require('../img/IMG_' + getCateg + '_04.webp'),
    img_5: require('../img/IMG_' + getCateg + '_05.webp'),
    img_7: require('../img/IMG_' + getCateg + '_07.webp'),
    img_8: require('../img/IMG_' + getCateg + '_08.webp'),

    ////// Transparent image banner ////////
    img_6: require('../img/IMG_' + getCateg + '_06.png'),
}

// Liste des textes sur slides
export const textOnSlide = (getCateg) => {
    ////// Texte for Consultant/Other ////////
    switch (getCateg) {
        case 'AD':
            return {
                'slide_1': ['Get top-quality advice', 'Our professional advisor are at your service!'],
                'slide_2': ['Your projects are important to us', 'Our team of specialists will be happy to guide you.'],
                'slide_3': ['Our team will drive you to success', 'Good advice to keep you moving forward!'],
            }
        case 'ES':
            return {
                'slide_1': ['Make yourself beautiful', 'Our professional are at your service!'],
                'slide_2': ['Book an appointment in one click', 'We\'re the reference in our domain'],
                'slide_3': ['Making you beautiful is our priority', 'Our services are varied and offered by professionals!'],
            }
        case 'FD':
            return {
                'slide_1': ['The flavour of our delicacies', 'We guarantee high-quality meals!'],
                'slide_2': ['Our meals are varied and distinctive', 'All our dishes are made by the hands of masters'],
                'slide_3': ['Order our services now', 'We make your outings, picnics and parties exceptional!'],
            }
        case 'CL':
            return {
                'slide_1': ['We clean your spaces', 'We guarantee high-quality service!'],
                'slide_2': ['Our services are varied and distinctive', 'We use special cleaning products'],
                'slide_3': ['Order our services now', 'We\'ll advise you on how to best maintain your property'],
            }
        default:
            break;
    }
}

// Liste des evenements
export let listevents = []

// Liste des users attribues au service
export let selectUser = []
export let oldUser = []

export const initialEvents = () => {
    let tab = [];
    const store = sessionStorage.getItem('st');
    client.get('api/store/' + store + '/schedule/',
        { withCredentials: true },
        {
            headers: { "X-CSRFToken": getCookie('csrftoken') },
        }
    ).then((res) => {
        tab = res.data;
        // console.log(tab)
        listevents.push(tab)
    }).catch((err) => {
        console.log(err);
        return false
    })
    return listevents[0];
}

export const register = (data) => {
    client.post('api/register/', data,
        {
            headers: { "X-CSRFToken": getCookie('csrftoken') },
        }
    ).then((res) => {
        store_data['id_user'] = res.data
        document.getElementById('loading_register').innerHTML = '<div class="alert alert-success" role="alert">Done!</div>';
    }).catch((err) => {
        document.getElementById('loading_register').innerHTML = '<div class="alert alert-danger" role="alert">Firstname or email already exist</br>Click <a href="/pricing">here to restart</a></div>';
        console.log(err)
    })
}

export const businessRegister = (store_data, step) => {
    switch (step) {
        case '1':
            client.post('api/store/add/', store_data,
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                document.getElementById('loading_business_1').innerHTML = '<div class="alert alert-success capitalize-hp" role="alert">Done!</div>';
                store_data['store_id'] = res.data.st_id
                sessionStorage.setItem('st', res.data.st_id);
                // sessionStorage.setItem('st_name', res.data.name);
            }).catch((err) => {
                document.getElementById('isSend').setAttribute('disabled', false);
                console.log(err)
                document.getElementById('loading_business_1').innerHTML = '<div class="alert alert-danger" role="alert">Oups Something went wrong please retry</div>';
            })
            break;
        case '2':
            client.post('api/store/update-contact/', store_data,
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                document.getElementById('loading_business_2').innerHTML = '<div class="alert alert-success capitalize-hp" role="alert">Done!</div>';
                store_data['store_id'] = res.data.st_id
            }).catch((err) => {
                console.log(err)
                document.getElementById('loading_business_2').innerHTML = '<div class="alert alert-danger" role="alert">Oups Something went wrong please retry</div>';
            })
            break
        default:
            break;
    }

}

export const login = (data) => {
    client.post('api/login/', data,
        {
            headers: { "X-CSRFToken": getCookie('csrftoken') },
        }
    ).then((res) => {
        // console.log(res.data);
        return true
    }).catch((err) => {
        console.log(err);
        return false
    })
}

export const socialLogin = () => {
    client.post('accounts/facebook/login/?process=login',
        {
            headers: { "X-CSRFToken": getCookie('csrftoken') },
        }
    ).then((res) => {
        console.log(res.data);
        return true
    }).catch((err) => {
        console.log(err);
        return false
    })
}

export const logout = () => {
    client.post('api/logout/',
        { withCredentials: true },
        {
            headers: { "X-CSRFToken": getCookie('csrftoken') },
        }
    ).then((res) => {
        console.log(res.data);
        return '1'
    }).catch((err) => {
        console.log(err);
        return false
    })
}

export const handleForm = (event) => {
    (event.target.name == 'username') ? data[event.target.name] = event.target.value.toLowerCase() : data[event.target.name] = event.target.value;
    return data
}

export const animation = () => {
    var reveals = document.querySelectorAll('.direction-left');

    for (let i = 0; i < reveals.length; i++) {
        var windowheight = window.innerHeight;
        var revealtop = reveals[i].getBoundingClientRect().top;
        var revealpoint = 50;
        if (revealtop < windowheight - revealpoint) {
            reveals[i].classList.add('active');
        } else {
            reveals[i].classList.remove('active');
        }
        if (revealtop < 0) {
            reveals[i].classList.remove('active');
        }

    }
}

export const fbLogin = async (accesstoken) => {
    let res = await axios.post(url + '/rest-auth/facebook/', {
        access_token: accesstoken,
    });
    console.log(res);
    return res.status;
};

export let indexnav = {
    i: "",
    percent: "",
};

document.addEventListener('contextmenu', (e) => { e.preventDefault(); });