import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Image, Button, Navbar, InputGroup } from 'react-bootstrap';
import { BsHandThumbsUpFill, BsShopWindow } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Iconbutton from '../components/Iconbutton';
import { client, getCookie, store_data } from '../data/functions';
import CategoryList from '../components/CategoryList';
import NavBarInactiveStore from '../components/NavBarInactiveStore';

const CoverPage = () => {
    const navigate = useNavigate();
    const [store, setStore] = useState([]);
    const [city, setCity] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectCity, setSelectCity] = useState();
    const [selectCategory, setSelectCategory] = useState();
    const [newStoreTab, setNewStoreTab] = useState([]);
    let list_country = [];
    let list_city = [];
    let list_category = [];
    useEffect(() => {
        client.get('api/store/',
            { withCredentials: true },
            {
                headers: { "X-CSRFToken": getCookie('csrftoken') },
            }
        ).then((res) => {
            setStore(res.data)
        }).catch((err) => {
            console.error(err)
        })
        try {
            client.post('api/logout/',
                { withCredentials: true },
                {
                    headers: { "X-CSRFToken": getCookie('csrftoken') },
                }
            ).then((res) => {
                sessionStorage.removeItem('id');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('email');
                sessionStorage.removeItem('super');
                sessionStorage.removeItem('key');
                sessionStorage.removeItem('ct')
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
            console.log(error)
        }

    }, [])

    const openStore = (id, name, category) => {
        sessionStorage.setItem('st', id);
        sessionStorage.setItem('st_name', name);
        sessionStorage.setItem('ct', category);
        window.open('/' + id, '_self');
        return id
    }

    const removeDuplicates = (data) => {
        return [...new Set(data)]
    }

    let Tab = []
    const selectRegion = (event) => {
        store.map((store, i) => {
            if (store.country == [event.target.value]) {
                list_city.push(store.city)
                list_category.push(store.category)
                Tab.push(store)
            }
        })
        setNewStoreTab(Tab)
        list_city = removeDuplicates(list_city.sort())
        list_category = removeDuplicates(list_category.sort())
        setCity(list_city)
        setCategory(list_category)
        setSelectCity('all')
        setSelectCategory('all')
        return
    }

    const activeCity = (event) => {
        newStoreTab.map((x, i) => {
            if (x.city == [event.target.value]) {
                list_category.push(x.category)
            }
        })
        setCategory(list_category)
        setSelectCity(event.target.value)
        return
    }

    const activeCategory = (event) => {
        setSelectCategory(event.target.value)
        return
    }
    let Tab2 = []
    const searchStore = (e) => {
        if (e.target.value.length > 2 && e.target.value.length <= 5) {
            store.map((store, i) => {
                if (store.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                    Tab2.push(store)
                }
            })
            setNewStoreTab(Tab2)
            list_city = removeDuplicates(list_city.sort())
            list_category = removeDuplicates(list_category.sort())
            setCity(list_city)
            setCategory(list_category)
            setSelectCity('all')
            setSelectCategory('all')
            return
        } else {
            store.map((store, i) => {
                if (store.country == [document.getElementById('activeCountry').value]) {
                    list_city.push(store.city)
                    list_category.push(store.category)
                    Tab.push(store)
                }
            })
            setNewStoreTab(Tab)
            list_city = removeDuplicates(list_city.sort())
            list_category = removeDuplicates(list_category.sort())
            setCity(list_city)
            setCategory(list_category)
            setSelectCity('all')
            setSelectCategory('all')
        }
    }

    return (
        <>
            <div className='cover-page'>
                <div className='container' id='scrollup'>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: '1px solid #eaeaea;',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100vh',
                        paddingTop: '10vh'
                    }}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'baseline'
                            }}>
                                <img src="./logo.png" alt="Logo" width="20" height="20" />
                                <h1 style={{
                                    fontFamily: 'Teko, sans-serif',
                                    fontOpticalSizing: 'auto',
                                    fontWeight: '300',
                                    fontStyle: 'normal',
                                }}>FLEXI SERVE</h1>
                            </div>
                            <br />
                            <Button variant="success" onClick={() => navigate('/pricing')}>Create your Business&nbsp; <BsShopWindow /></Button>
                            <br />
                            <div style={{
                                border: '1px solid #eaeaea',
                                marginBottom: '5px',
                                // display: 'flex',
                                // justifyContent: 'center',
                                padding: '5px',
                                backgroundColor: '#042cde24',
                                borderRadius: '10px'
                            }}>
                                <Row xs={12} md={12} className='mx-0'>
                                    <Row xs={12} md={12} className='mx-0'>
                                        <InputGroup className="mb-2">
                                            <Form.Control
                                                placeholder="Search..."
                                                aria-label="Search"
                                                aria-describedby="Search"
                                                // value=''
                                                onChange={(e) => searchStore(e)}
                                                id='activeSearch'

                                            />
                                            <Button variant="secondary" id="button-addon2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                </svg>
                                            </Button>
                                        </InputGroup>
                                    </Row>
                                    <Row xs={12} className='mx-0'>
                                        <Col xs={4} md={4}>
                                            <Form.Group controlId="formSelectRegion">
                                                <Form.Label style={{ fontSize: 'clamp(0.6rem, 4vw, 0.8rem)' }} className='mb-0'>Country :</Form.Label>
                                                <Form.Select id='activeCountry' onChange={(e) => selectRegion(e)}>
                                                    <option value='all'>...</option>
                                                    {store.map((store, i) => {
                                                        list_country.push(store.country)
                                                    }
                                                    )}
                                                    {
                                                        list_country = removeDuplicates(list_country)
                                                    }
                                                    {list_country.map((tab, i) =>
                                                        <option value={tab} key={i}>{tab}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={4}>
                                            <Form.Group controlId="formSelectCity">
                                                <Form.Label style={{ fontSize: 'clamp(0.6rem, 4vw, 0.8rem)' }} className='mb-0'>City :</Form.Label>
                                                <Form.Select id='activeCity' onChange={(e) => activeCity(e)}>
                                                    <option value='all'>...</option>
                                                    {city.map((tab, i) =>
                                                        <option value={tab} key={i}>{tab}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4} md={4}>
                                            <Form.Group controlId="formSelectCategory">
                                                <Form.Label style={{ fontSize: 'clamp(0.6rem, 4vw, 0.8rem)' }} className='mb-0'>Category :</Form.Label>
                                                <Form.Select id='activeCategory' onChange={(e) => activeCategory(e)}>
                                                    <option value='all'>...</option>
                                                    {category.map((tab, i) =>
                                                        <option value={tab} key={i}>{tab}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                            </div>
                        </div>
                        <div className="list-barber">
                            <Row xs={1} md={2} lg={3}>
                                {newStoreTab.filter((item) => {
                                    const activeCountry = document.getElementById('activeCountry');
                                    const activeCateg = document.getElementById('activeCategory');
                                    const activeCity = document.getElementById('activeCity');
                                    const activeSearch = document.getElementById('activeSearch');
                                    let result = ''
                                    if (selectCity === 'all' && selectCategory === 'all' && activeSearch.value == '') {
                                        result = item.country.includes(activeCountry.value)
                                    }
                                    else if (selectCity === 'all' && selectCategory != 'all' && activeSearch.value == '') {
                                        result = item.category.includes(activeCateg.value)
                                    } else if (selectCity != 'all' && selectCategory != 'all' && activeSearch.value == '') {
                                        result = item.category.includes(activeCateg.value)
                                    } else if (selectCategory === 'all' && selectCity != 'all' && activeSearch.value == '') {
                                        result = item.city.includes(activeCity.value)
                                    } else if (selectCategory != 'all' && activeSearch != 'all') {
                                        result = item.category.includes(activeCateg.value)
                                    }
                                    else if (selectCity === 'all' && selectCategory === 'all' && activeSearch.value != '') {
                                        const storeName = item.name.toLowerCase()
                                        const sch = activeSearch.value.toLowerCase()
                                        result = storeName.includes(sch)
                                    }

                                    return result
                                }).map((store, i) =>
                                    <Col className='item' id={store.id} key={i} onClick={() => openStore(store.id, store.name, store.category)}>
                                        <div id="barbershop-img">
                                            <Image src={store.image} width="80px" height="80px" alt='flexiserve_store_img' />
                                        </div>
                                        <div className="details">
                                            <div id='title' style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                                <b style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}>{store.name}</b>
                                                {
                                                    (store.certify == '2') ? <img src="./certif.png" alt="certify" style={{
                                                        position: 'relative',
                                                        width: '25px',
                                                        height: '25px',
                                                        top: '-5px',
                                                        right: '-10px'
                                                    }} /> : ''
                                                }
                                            </div>
                                            <div id='adr'><b>Adresse : </b><span>{store.address}</span></div>
                                            <div id='statut'>
                                                <div><b>Phone : </b><i>{store.tel}</i></div>
                                                <div><b>City : </b><i>{store.city}</i></div>
                                                {/* <div id='stars'>
                                            <BsHandThumbsUpFill />&nbsp;{store.like}
                                        </div> */}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        {/* <a href="/home">Go to home page</a> */}
                    </div>
                    <Iconbutton></Iconbutton>
                    {/* <Formconnect show={modalFormShow} onHide={() => SetModalFormShow(false)}></Formconnect> */}
                </div>
            </div>
        </>


    );
};
export default CoverPage; 